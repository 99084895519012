@import '../theme.scss';

.privacyAgreementInput {
  font-size: 0;

  &.checked {
    .checkbox {
      .check {
        opacity: 1;
      }
    }
  }

  &.invalid {
    .checkbox {
      border-color: $color-red;
    }
  }

  .checkbox {
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid $color-dark-grey;
    background-color: $color-light-grey;
    position: relative;
    transition: border 0.2s ease-in-out;

    .check {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      width: 50%;
      height: 50%;
      background-color: $color-blue;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 35px);
    cursor: pointer;

    p {
      font-size: 12px;
      line-height: 1.1;
      letter-spacing: 0.01em;
      color: $color-dark-grey;
      text-align: left;

      a {
        color: $color-blue;
        text-decoration: underline;
      }
    }
  }
}
